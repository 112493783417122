import * as Sentry from "@sentry/nuxt";

Sentry.init({
	// If set up, you can use your runtime config here
	// dsn: useRuntimeConfig().public.sentry.dsn,
	dsn: "https://be37fe238b5e68aa36255add7547d6b2@o4504796632973312.ingest.us.sentry.io/4508104202125312",
	// Tracing
	// We recommend adjusting this value in production, or using a tracesSampler for finer control.
	tracesSampleRate: 1.0,
	//  Capture 100% of the transactions	
});
